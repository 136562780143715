import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import "./Login.css";
import Button from 'react-bootstrap/Button';
const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/api/auth/login`, { username, password });
            const { token } = response.data;
            localStorage.setItem('token', token);
            login(username);
            navigate('/view');
        } catch (error) {
            console.error('Error. Cannot login.', error);
            setMessage("Incorrect login details.")
        }
    };

    return (
        <div>
            <div className='logField'>
                <form onSubmit={handleSubmit} className='text-center'>
                    <input type="text" placeholder="User name" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <Button type="submit" variant="outline-dark">Login</Button>
                </form>
                {message && <p>{message}<a href='/forgotpassword'>I need help!</a></p>}
            </div>
        </div>
    );
};

export default Login;
