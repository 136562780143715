import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import './AddElement.css';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { RefreshContext } from './RefreshContext';
const API_URL = process.env.REACT_APP_API_URL;

const AddElement = () => {
    const { triggerRefresh } = useContext(RefreshContext);

    const [formData, setFormData] = useState({
        schemeContent: '',
        schemeDescription: '',
        schemeCategory: '',
    });

    const { username } = useContext(AuthContext);

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const { schemeContent, schemeDescription, schemeCategory } = formData;

        if (!schemeContent || !schemeDescription || !schemeCategory) {
            alert("All fields are required");
            return;
        }

        const newElement = {
            schemeContent,
            schemeDescription,
            schemeCategory,
            createdBy: username,
        };

        axios
            .post(`${API_URL}/api/new`, newElement)
            .then(res => {
                setFormData({
                    schemeContent: '',
                    schemeDescription: '',
                    schemeCategory: '',
                });
                triggerRefresh();
            })
            .catch(err => {
                console.error('Error creating new element:', err.message);
            });
    };

    return (
        <div className='form'>
            <main>
                <section className="w-100 d-flex flex-column justify-content-center align-items-center">
                    <form className="w-96" onSubmit={onSubmit}>
                        <textarea
                            className="w-100 border p-2 mb-2 fs-7 addTextarea"
                            type="text"
                            name="schemeContent"
                            placeholder="Paste code here..."
                            value={formData.schemeContent}
                            onChange={onChange}
                            wrap="off"
                            spellCheck="false"
                        />

                        <input
                            className="w-100 border p-2 mb-2 fs-7 h-25"
                            type="text"
                            name="schemeDescription"
                            placeholder="Short description"
                            value={formData.schemeDescription}
                            onChange={onChange}
                        />
                        <select
                            className="w-100 border p-2 mb-2 fs-7 h-25"
                            name="schemeCategory"
                            value={formData.schemeCategory}
                            onChange={onChange}
                        >
                            <option value="">Select Category</option>
                            <option value="html">HTML</option>
                            <option value="javascript">JavaScript</option>
                            <option value="python">Python</option>
                            <option value="vba">Visual Basic</option>
                            <option value="other">Other</option>
                        </select>
                        <div className="align-items-center text-center">
                            <Button type="submit" className="btn outline-success m-1" variant="outline-dark">Create</Button>
                        </div>
                    </form>
                </section>
            </main>
        </div>
    );
};

export default AddElement;
