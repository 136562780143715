import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './UserSettings.css';
import { Button } from "react-bootstrap";
import ChangePasswordForm from './changePassword';
const API_URL = process.env.REACT_APP_API_URL;

const UserSettings = () => {
  const [sharedBy, setSharedBy] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [usernames, setUsernames] = useState({});
  const [message, setMessage] = useState('');
  const [yourKey, setYourKey] = useState('aaa');
  const { username } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/users/${username}`);
        setSharedBy(response.data.sharedBy || []);
        setYourKey(response.data.company)
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (username) {
      fetchUserData();
    }
  }, [username]);

  useEffect(() => {
    const fetchUsernamesByCompany = async () => {
      try {
        const requests = sharedBy.map(id => axios.get(`${API_URL}/api/users/company/${id}`).catch(() => null));
        const responses = await Promise.all(requests);
        const usernamesMap = responses.reduce((acc, response, index) => {
          if (response && response.data && response.data.username) {
            acc[sharedBy[index]] = response.data.username;
          }
          return acc;
        }, {});
        setUsernames(usernamesMap);
      } catch (error) {
        console.error('Error fetching usernames by company:', error);
      }
    };

    if (sharedBy.length > 0) {
      fetchUsernamesByCompany();
    }
  }, [sharedBy]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveSharedBy = async () => {
    try {
      const newSharedByArray = inputValue.split(',').map(item => item.trim()).filter(item => item !== '');
      const updatedSharedBy = [...sharedBy, ...newSharedByArray];
      await axios.put(`${API_URL}/api/users/sharedBy`, { username, sharedBy: updatedSharedBy });
      setMessage('You added this user to your follow list');
      setInputValue('');
      setSharedBy(updatedSharedBy);
    } catch (error) {
      console.error('Error updating SharedBy:', error);
      setMessage('Something went wrong. You cannot start follow this user.');
    }
  };

  const deleteElement = async (id) => {
    const confirmDelete = window.confirm("Unfollow this user?");
    if (confirmDelete) {
      try {
        const updatedSharedBy = sharedBy.filter(item => item !== id);
        setSharedBy(updatedSharedBy);
        await axios.put(`${API_URL}/api/users/sharedBy`, { username, sharedBy: updatedSharedBy });
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const copyKeyToClipboard = (key) => {
    navigator.clipboard.writeText(key)
    document.getElementById('copyKey').innerText = "Copied!"
    setTimeout(() => {
      document.getElementById('copyKey').innerText = "Copy"
    }, 2000)
  }

  return (
    <>
      <div className='section'>
        <h1>Sharing settings</h1>
        <input className='inputkey w-50 p-2 my-3 fs-7'
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={message.length > 0 ? "User added to follow list" : "Paste secret key of user you would like to follow"}
        />
        <Button variant="success" onClick={handleSaveSharedBy}>Follow</Button>
        <div className='usersTilts-container'>
          {sharedBy.map((sharedUser, id) => (
            <div className='usersTilts' key={sharedUser}>
              <Button
                style={{ width: '100%', margin: '2px' }}
                variant="outline-danger"
                onClick={() => deleteElement(sharedUser)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path>
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"></path>
                </svg>
                {usernames[sharedUser] || sharedUser}
              </Button>
            </div>
          ))}
        </div>
        <div className='sharingKey'>
          <p>Here's your sharing key:<span className='key'>{yourKey}</span></p>
          <Button id='copyKey' variant="outline-success" size='sm' onClick={() => copyKeyToClipboard(yourKey)}>Copy</Button>
        </div>
      </div>
      <div className='section'>
        <h1>Password settings</h1>
        <ChangePasswordForm />
      </div>
    </>
  );
}

export default UserSettings;
